.customChatBot1 .card_view_parent {
  padding-top: 4px;
}
.customChatBot1 .singleCardParent {
  margin-right: 2px;
  margin-left: 2px;
  border: 0px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  display: inline-block;
  border: solid 1px #e24c2d;
  cursor: pointer;
  /* height: 147px; */
  width: 128px;
  overflow: hidden;
}
.singleCardParentIn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customChatBot1 .card_main_body {
  overflow: auto;
  overflow-y: scroll;
  margin-left: 10px;
}
.allCardParent {
  height: fit-content;
}
.customChatBot1 .card_image {
  width: 100%;
  height: 104px;
  object-fit: cover;
}
.customChatBot1 .card_content {
  /* padding-top: 1px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  background-color: #f1f0f0;
  height: 36px; */
  display: none;
}
.customChatBot1 .h5CardContent {
  margin-top: 5px;
  color: "#000000";
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 2px;
}
.customChatBot1 .card_content_button {
  padding-top: 1px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #fd6a4b;
}

.h5CardContent_button {
  margin-top: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 1px;
  color: #fff;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
}

.card_content_button {
  height: 40px;
  display: flex;
  align-items: center;
}

.card_content_button:hover {
  background-color: hsl(253, 100%, 78%);
  color: #000;
  cursor: pointer;
}
.h5CardContent_button:hover {
  color: #000;
}

.disableCardParent {
  opacity: 0.5;
}
