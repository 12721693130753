.chatbot_body {
  background-color: #fff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  padding-top: 4px;
}
.icon_twoLine {
  /*font-size: 35px !important;*/
  text-align: center;
  margin: 0 auto;
}
.QuickReplyMainParent {
  padding-left: 0;
  padding-top: 5px;
  padding-right: 70px;
}
.surveyCompleted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.thankLine {
  padding-top: 35px;
  width: 100%;
  text-align: center;
}
.surveyLogo {
  width: 100%;
  margin-top: 30px;
}

#chatbot_body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
#chatbot_body::-webkit-scrollbar-track {
  background: transparent;
}

#chatbot_body::-webkit-scrollbar-thumb {
  background: #40547b;
  border-radius: 20px;
}

.quickReplyContainer {
  padding-left: 26px;
  padding-top: 10px;
  max-width: 95%;
}

.lineUp {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
}

.lineUp:before {
  content: "";
  background: #fd6a4b;
  width: 400px;
  height: 2px;
  position: absolute;
  left: 28%;
  top: -16px;
}

.createSurveyBtn {
  width: auto;
  padding: 6px 20px !important;
}

@media only screen and (max-width: 550px) {
  .lineUp:before {
    width: 100% !important;
    left: 0 !important;
  }
}
