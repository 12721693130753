.imagesStyling {
  /* width: 200px; */
  /* lsdfdsf */
  width: 120px;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border: 1px solid #fc644c;
  padding: 10px;
}
.imagesStyling img {
  width: 100%;
  border-radius: 7px;
}
