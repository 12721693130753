.quickButtonDiv {
  display: inline-block;
  padding-left: 0px !important;
  padding-right: 20px !important;
  width: 40%;
}
.quickButton {
  padding: 5px 10px !important;
  margin: 2px;
  border-radius: 13px !important;
  background-color: #fd6a4b !important;
  color: #ffffff !important;
  border: none !important;
  width: 100%;
  /* background-image: linear-gradient(90deg,#1500ff,#7c00f9); */
}

.quickButtonDiv .btn-primary {
  border: 1px solid #fd6a4b !important;
}

.button_text {
  font-size: 14px;
  word-break: break-word;
}

@media only screen and (max-width: 550px) {
  .quickButtonDiv {
    width: 100%;
    padding-bottom: 10px !important;
    padding-right: 0 !important;
  }
}
