@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');

code {
  font-family: 'Mulish', sans-serif;
}

#root {
  font-family: 'Mulish', sans-serif;
  font-weight: 500;
  line-height: 1.4;
  font-size: 14px;
}

* {
  font-family: 'Mulish', sans-serif;
}
