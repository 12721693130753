.mainHeaderContainer {
  width: 100%;
  padding: 10px 0px;
  max-width: 90%;
}

.mainHeaderCol1 img {
  width: 100%;
  max-width: 250px;
}

.mainHeaderCol2,
.mainHeaderCol3 {
  align-self: center;
}

.mainHeaderCol2 ul,
.mainHeaderCol3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
    /* justify-content: space-evenly; */
}

.mainHeaderCol3 ul {
  justify-content: flex-end;
  column-gap: 2px;
}

.mainHeaderCol2 ul {
  justify-content: center;
}
.mainHeaderCol2 ul li,
.mainHeaderCol3 ul li {
  font-weight: 700;
  position: relative;
  font-size: 16px;
}
.mainHeaderCol2 ul li:first-child{
  margin-left: 0;
}
.mainHeaderCol2 ul li a,
.mainHeaderCol3 ul li a {
  color: #000 !important;
  text-decoration: none;
}

.mainHeaderCol2 ul li:after,
.mainHeaderCol3 ul li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: -9px;
  right: 0;
  transform: scaleX(0);
  transition: all 0.5s ease-in-out;
}

.mainHeaderCol2 ul li:hover:after,
.mainHeaderCol3 ul li:hover:after {
  transform: scaleX(1);
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
  display: flex;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li.none {
  margin: 0px !important;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #40547b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.expanded {
  background-color: #ffffff;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  top: 100%;
  z-index: 99999;
}
.navigation-menu.expanded {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
  border-radius: 8px;
  padding-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .hamburger {
    display: block;
    position: absolute;
    right: 0;
  }
  .mainHeaderBlock {
    position: relative;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    margin: 0;
    width: 100%;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu .mainHeaderCol2 ul li a,
  .navigation-menu .mainHeaderCol3 ul li a {
    color: #ffffff !important;
  }
  .mainHeaderCol2 ul li:after,
  .mainHeaderCol3 ul li:after {
    bottom: 0px;
    background: #ffffff;
  }
  .none {
    display: none;
  }
  .navigation-menu li a {
    width: 100%;
    padding: 25px 0 10px 0;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
  .navigation-menu.expanded {
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .mainHeaderContainer {
    display: none;
  }
  .mainHeaderCol1 img {
    max-width: 200px !important;
  }
}
