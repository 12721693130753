/**** LATEST CSS ****/

.footerInputContainer input {
  background: #fff !important;
  padding: 8px 10px;
  border-radius: 10px;
  padding-right: 100px;
}

.sendButtonContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
}
.send_icon {
  max-width: 12px;
  margin-left: 5px;
  margin-top: 1px;
}

.sendButtonContainer button {
  background: #40547b;
  color: #fff;
  width: auto;
  padding: 3px 20px;
  border-radius: 9px;
  border: 2px solid #40547b;
  height: 100%;
  display: flex;
  align-items: center;
}

.helpButtonContainer button {
  z-index: 20;
  background: #40547b;
  color: #fff;
  width: auto;
  padding: 3px 20px;
  border-radius: 9px;
  border: 2px solid #40547b;
}

.footerMessageContainer {
  position: relative;
  column-gap: 5px;
}

.poweredByFooter {
  text-align: right;
  margin-top: 10px;
}

/***** OLD CSS  *****/
.customChatBot1 .footerTextInput {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  /* border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; */
  border-radius: 20px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 10px;
}
.customChatBot1 .row {
  margin: 10px auto !important;
  height: 40px;
}

.customChatBot1 .sendMessage {
  padding-right: 5px;
  padding-left: 5px;
}
.customChatBot1 .sendInoutBar {
  background: transparent;
  outline: none;
  border: 0px;
  width: 100%;
  padding-left: 10px;
}
.customChatBot1 .sendMessageButton {
  display: inline-block;
  padding: 0px !important;
  cursor: pointer;
  margin: 0px 0px 9px 15px;

  vertical-align: middle;
}
/* .customChatBot1 .sendMessageButton:hover{
  border: 1px solid #1c4fc3;
  border-radius: 100%;
  background-image: linear-gradient(to right, #8b00ff , #6000c1);
  color: #fff;
} */
.customChatBot1 .footerTextInput .col-md-2 {
  max-width: 6% !important;
}
.customChatBot1 .footerTextInput .col-md-2:hover {
  /* border: 1px solid #1c4fc3; */
  /* border-radius: 100%; */
  /* background-color: #1c4fc3; */
  color: #40547b;
}
.customChatBot1 .sendMessageButton .material-icons {
  margin-left: 0px;
  margin-top: 8px;
}
.customChatBot1 .poweredByFooter {
  font-size: 10px;
  text-align: right;
  color: #fff;
}
.customChatBot1 .poweredByFooter a {
  color: #fff !important;
}
.customChatBot1 .footerParent {
  padding: 15px 10px;
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 2px solid #e6e6e6;
  /* background-color: #fff; */
  margin-top: 1px;
}
.footerHelpButton {
  display: inline-block;
  color: "#1C4FC3";
  font-weight: 600;
  background: #40547b;
  /* background: #e9ecef; */
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
  color: #fff;
  padding-right: 20px;
  margin-bottom: 2px;
  margin-left: 10px;
  padding-left: 20px;
}

.footerHelpButton:hover {
  background: #e9ecef;
  color: #e24c2d;
  cursor: pointer;
  border: 1px solid #e24c2d;
}
.redmic {
  display: inline-block;
  /* margin-top: 5px; */
  cursor: pointer;
  vertical-align: middle;

  color: #ff0000;
}
.micButton {
  display: inline-block;
  margin-bottom: 5px;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 5px;
  padding: 0% !important;
}

.micButton:hover {
  border: 1px solid;
  border-radius: 100%;
  background-color: #1c4fc3;
  color: #fff;
}

.customChatBot1 .footerTextInput .col-md-3 {
  max-width: 9% !important;
  /* border: 1px solid ;
    border-radius: 100%; */
}

.customChatBot1 .redmic .canvas {
  display: block;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.customChatBot1 .redmic .ball {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 11px;
  margin: 0 2px;
  animation: 2s bounce ease infinite;
  animation: 2s bounce ease infinite;
}
.customChatBot1 .redmic .blue {
  background-color: #0053df;
}
.customChatBot1 .redmic .red {
  background-color: #952b2b;
  animation-delay: 0.25s;
}
.customChatBot1 .redmic .yellow {
  background-color: #ff7510;
  animation-delay: 0.5s;
}
.customChatBot1 .redmic .green {
  background-color: #34a952;
  animation-delay: 0.75s;
}

@keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}

#container_mic {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

#container_mic .circle {
  border-radius: 50%;
  background-color: #1c4fc3;
  width: 22px;
  height: 22px;

  position: absolute;
  opacity: 0;
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  top: 3px;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

.bottomFooterContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.foot_icon {
  max-width: 20px;
}
.footSurveyContainer h2 {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
.surveyFootInfoContainer p {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
}
.ipDiv {
  position: relative;
  flex: 0 0 82%;
}
.footerMessageContainer {
  display: flex;
  justify-content: space-around;
}
.flexFooter {
  display: flex;
  align-items: center;
  column-gap: 1px;
}

.surveyFootInfoContainer img {
  width: 100%;
  max-width: 115px;
}

.surveyFootInfoContainer {
  position: relative;
}

.surveyFootInfoContainer a {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 950px) {
  .footerMessageContainer {
    column-gap: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .footerMessageContainer {
    flex-direction: column;
    row-gap: 10px;
    column-gap: unset;
  }

  .surveyFootInfoContainer {
    align-self: center;
  }
}
@media only screen and (max-width:400px){
  .ipDiv {
    position: relative;
    flex: 0 0 76%;
  }
  .sendButtonContainer button {
    padding: 2px 6px;
  }
}