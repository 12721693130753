.customChatBot1 .chat-window {
  width: 100%;
  max-width: 60%;
  height: 83vh;
  box-sizing: border-box;
  box-shadow: 2px 1px 10px 3px #9d888899;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 99;
  background: #ffffff;
  margin: auto;
  border-radius: 8px !important;
}
.chat-window-div {
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url(../../assests/images/surveyBg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.iframe-chat-window {
  width: 100%;
  height: auto !important;
  display: flex;
  background-image: none !important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.iframe-chat-window .chat-window {
  width: 100%;
}

/* .iframe-chat-window .chatbot_body {
  height: 300px;
} */

.surveyLogoContainer {
  margin: 20px 0px 0px;
  width: 100%;
  max-width: 60%;
  display: flex;
  justify-content: center;
}

.surveyLogoContainer img {
  width: 100%;
  max-width: 250px;
  height: auto;
}

.iframe-chat-window .chat-window {
  height: 500px;
}

@media only screen and (max-width: 950px) {
  .customChatBot1 .chat-window {
    width: 90%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    max-width: 90%;
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  .customChatBot1 .chat-window {
    width: 100%;
    height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px !important;
    /* border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
  }
}

.floatingLogo {
  position: absolute;
  bottom: 21px;
  right: 28px;
}

.floatingLogo p {
  margin-bottom: 1px;
}

.floatingLogo img {
  max-width: 200px;
}

.floatingLogo a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cardCon {
  display: inline-block;
}

.disabledSurveyContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.disabledSurveyContainer a {
  background: #40547b;
  color: #fff;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 18px;
  border: 2px solid #40547b;
  font-weight: 600;
}

.disabledSurveyContainer a:hover {
  background: #fff;
  color: #40547b;
  text-decoration: none;
}

.disabledSurveyContainer h3:nth-child(2) {
  position: relative;
}

.disabledSurveyContainer h3:nth-child(2):before {
  content: "";
  background: #67646447;
  width: 350px;
  height: 2px;
  position: absolute;
  bottom: -18px;
  left: 65px;
}

.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loaderContainer img {
  max-width: 30%;
}

.loaderContainer p {
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

@media only screen and (max-width: 550px) {
  .customChatBot1 .chat-window {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    flex: 1 0 auto;
  }
}
