@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap);
code {
  font-family: 'Mulish', sans-serif;
}

#root {
  font-family: 'Mulish', sans-serif;
  font-weight: 500;
  line-height: 1.4;
  font-size: 14px;
}

* {
  font-family: 'Mulish', sans-serif;
}

/**** LATEST CSSS ******/

.headerContainer {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
  padding: 10px 20px;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.headerContainer img {
  width: 70px;
  min-width: 50px;
  min-height: 50px;
}

.surveyInfoContainer .surveyImgContainer img {
  border-radius: 5px;
}

.surveyInfoContainer {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.aboutSurveyContainer h2,
.aboutSurveyContainer h4,
.aboutSurveyContainer p {
  color: #fff !important;
  margin: 0px;
}

.aboutSurveyContainer h2 {
  font-size: 25px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
}

.aboutSurveyContainer h4 {
  color: #000;
  font-size: 16px;
}

.headerCol1 .poweredByFooter {
  margin-top: 0px !important;
}

.headerCol2 {
  align-self: center;
  text-align: right;
}

.headerCol2 button {
  border: none;
  border-radius: 8px;
  color: #000;
  font-weight: 600;
  padding: 5px 20px;
  font-size: 16px;
}

.headerCol2 button:hover {
  background: #40547b;
  color: #fff;
}

.headerCol2 button:focus {
  outline: none;
}

/**** OLD CSS ***/
.customChatBot1 .header_promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: height 0.4s linear;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 26vh;
  position: -webkit-sticky;
  position: sticky;
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
}
/* .customChatBot1 .header_promo_img {
  width: 10vh;
  height: 10vh;
  position: -webkit-sticky;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 2px;
} */

.customChatBot1 .header_title_promo {
  text-align: center;
  color: #f9f9f9;
}

.customChatBot1 .header_container_inner_promo {
  align-items: center;
  text-align: center;
  padding-top: 10px;
  width: 100%;
}

.customChatBot1 .h5Style_promo {
  margin: 1px;
}
.customChatBot1 .h6Style_promo {
  margin: 1px 1px 1px 5px;
}

.customChatBot1 .drop_down_icon {
  float: left;
  color: #ffffff;
  position: absolute;
  padding: 8px;
  top: 5px;
  left: 5px;
}

.customChatBot1 .trancription_icon {
  float: left;
  color: #ffffff;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 5px;
}
.customChatBot1 #trancription_icon:hover {
  cursor: pointer;
  background-color: #e6e6e6cc;
  color: #000;
  border: blanchedalmond 1px;
  border-radius: 11px;
  padding-bottom: 1px;
}
.customChatBot1 #trancription_icon:hover #tooltip {
  visibility: visible;
}
.customChatBot1 #drop_down_icon:hover {
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #e6e6e6cc;
  color: #000;
  border: blanchedalmond 1px;
  border-radius: 11px;
  padding-bottom: 1px;
}

.customChatBot1 .header_main {
  display: flex;
  flex-direction: column;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 56px;
  position: -webkit-sticky;
  position: sticky;
  background: #1c4fc3;
  /* background-image: linear-gradient(to right, #8b00ff , #6000c1); */
  transition: height 0.5s linear;
}

.customChatBot1 .header_main .header_container_inner_promo {
  align-items: unset;
  text-align: unset;
  padding-top: 0px;
  display: flex;
}
.customChatBot1 .listViewMenu {
  position: absolute;
  right: 10px;
  text-align: left;
  top: 50px;
  border: 1px solid #ddd;
}

.customChatBot1 .header_main .header_promo_img {
  width: 40px;
  height: 40px;
  position: -webkit-sticky;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 2px;
}
.customChatBot1 .header_main .header_img_div {
  margin: 5px;
  padding-left: 90px;
}

.customChatBot1 .header_main .header_title_promo {
  text-align: left;
  color: #f9f9f9;
}
.customChatBot1 .header_main .h5Style_promo {
  margin: 5px 5px 1px 5px;
  font-size: 1.4rem;
}
.customChatBot1 .header_main .h6Style_promo {
  font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .customChatBot1 .header_main {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .headerContainer img {
    min-width: 30px;
    min-height: 30px;
  }
  .customChatBot1 .header_promo {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  .customChatBot1 .header_main {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .customChatBot1 .header_promo {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.customChatBot1 #tooltip {
  position: absolute;
  left: 0px;
  top: 0px;
  visibility: hidden;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;

  display: inline-block;
}

.customChatBot1 #tooltip.right {
  margin-left: 5px;
}

.customChatBot1 #tooltip.left {
  margin-left: -5px;
}

.customChatBot1 #tooltip.top {
  margin-top: -5px;
}

.customChatBot1 #tooltip.bottom {
  margin-top: 5px;
}

.customChatBot1 #tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.customChatBot1 #tooltip.right .tooltip-arrow {
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.customChatBot1 #tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.customChatBot1 #tooltip.left .tooltip-arrow {
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  right: -5px;
  left: auto;
}

.customChatBot1 #tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.customChatBot1 #tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.customChatBot1 #tooltip .tooltip-label {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.customChatBot1 .trancription_icon_Css {
  float: left;
  color: #ffffff;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 5px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #338def;
  border: blanchedalmond 1px;
  border-radius: 11px;
  padding-bottom: 1px;
}
.customChatBot1 .header_main {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
}

@media only screen and (max-width: 550px) {
  .aboutSurveyContainer h2 {
    font-size: 21px;
  }

  .aboutSurveyContainer h4 {
    font-size: 14px;
  }

  .headerContainer img {
    width: 60px !important;
  }
}

.customChatBot1 .message_parent {
  width: 100%;
  position: relative;
  margin-top: 2px;
  display: inline-block;
  margin-left: 5px;
}
.customChatBot1 .messageImg .message_bot_logo {
  width: 40px;
  height: 40px;
  transform: translate(0px, 20px);
  z-index: 99;
  margin-top: -20px;
}
.messageImgICONUser .message-user-logo {
  width: 40px;
  height: 40px;
  transform: translate(0px, 20px);
  z-index: 99;
  margin-top: -20px;
  position: -webkit-sticky;
  position: sticky;
}
.customChatBot1 .message_bot_logo {
  width: 27px;
  height: 27px;
  position: -webkit-sticky;
  position: sticky;
  background: #ffffff;
  border-radius: 50%;
  /* padding: 2px; */
  /* border: #ddd 1px solid; */
}

.customChatBot1 .bot-message {
  /* background-color: #1c4fc3; */
  background: linear-gradient(
    270.27deg,
    #e9ae16 -10.48%,
    #ff7e45 28.42%,
    #fc644c 123.16%
  );
  padding: 5px 20px;
  border-radius: 20px;

  width: -webkit-fit-content;

  width: -moz-fit-content;

  width: fit-content;
  clear: both;
  border-color: #eaeaea;
  border-width: 0;
  border-style: solid;
  margin-right: 5px;
  display: inline-block;
  margin: 0px !important;
  /* border-top-right-radius:12px;
border-bottom-right-radius:12px;
border-bottom-left-radius:12px; */
}
.MuiGrid-root.mainHeaderBlock {
  flex-wrap: nowrap;
}
.customChatBot1 .messageImgICONUser {
  /* height: 24px; */
  padding-right: 10px;
  vertical-align: top;

  text-align: right;
}
.Usermessages > div {
  overflow: hidden;
  clear: both;
}
.customChatBot1 .messageImg {
  padding-left: 9px;
  vertical-align: top;
  /* margin-right: 14px; */
  /* display: inline-block !important; */
  padding-bottom: 5px;
}
.customChatBot1 .messageTextBot {
  display: inline-block !important;
  margin-left: 8px !important;
  margin-right: 95px !important;
  padding-bottom: 2px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
/* .customChatBot1 .messageTextBotWithIcon:before {
  content: "";
  position: absolute;
  top: -9px;
  left: 0px;
  border-color: transparent transparent transparent #e9ae16 ;
  border-style: solid;
  border-width: 10px 20px 0px 16px;
  height: 0px;
  width: 0px;
} */
.customChatBot1 .messageTextWithLogoBot {
  padding-bottom: 2px;
}
.customChatBot1 .messageTextUser {
  float: right;
  margin-right: 10px;
  padding-bottom: 0px;
  position: relative;
  padding-left: 125px;
  padding-right: 20px;
}
ICONUser .userPersonLogo {
  display: block;
  float: right;
  width: 28px;
  height: 28px;
  position: -webkit-sticky;
  position: sticky;
  background: #ffffff;
  color: #1c4fc3;
  border-radius: 50%;
  padding: 2px 0px 0px 0px;
  font-size: 28px;
  border: #ddd 1px solid;
}
/* .customChatBot1 .messageTextUser:before {
  content: "";
  position: absolute;
  border-width: 6px 13px 0px 8px;
  height: 0px;
  width: 0px;
  top: -9px;
  right: 0px;
  border-color: transparent #40547b transparent transparent  ;
  border-style: solid;
  border-width: 10px 20px 0px 16px;
} */
.messageImgICONUser {
  padding-bottom: 5px;
}
.customChatBot1 .Usermessages {
  margin-top: 0px;
  overflow: hidden;
  clear: both;
}
.customChatBot1 .user-message {
  background-color: #40547b;
  /* background-image :linear-gradient(to right, #1500ff , #7c00f9); */
  /* background-image: linear-gradient(to right, #c6c3c3 , #e6e6e6); */
  padding: 5px 20px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  clear: both;
  border: 1px solid #40547b;
  border-radius: 20px;
  color: #ffffff;
  /* border-top-left-radius:12px;
border-bottom-right-radius:12px;
border-bottom-left-radius:12px; */
}
.customChatBot1 .white-text {
  padding: 3px 5px 3px 5px;
  font-size: 14px;
  word-break: break-word;
}
.customChatBot1 .loaderMessage {
  width: 100px;
  padding-left: 45px;
}
.customChatBot1 .black-text {
  padding: 2px 5px 3px 3px;
  font-size: 14px;
  color: #ffffff;
  word-break: break-word;
}
.customChatBot1 .black-text a{
  color: #ffffff;
  text-decoration: underline;
  letter-spacing:0.5px;
}
.customChatBot1 .black-text a:hover,.customChatBot1 .black-text a:focus{
  color: #1c4fc3;
}
.customChatBot1 .timmer {
  text-align: end;
  font-size: 12px;
  padding-right: 5px;
}

.customChatBot1 .loaderMessageBox {
  clear: both;
  /* display: flex; */
}

.customChatBot1 .loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 0.5em;
  height: 0.6em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.6s infinite ease-in-out;
  animation: load7 1.6s infinite ease-in-out;
}

.customChatBot1 .loader {
  color: #1c4fc3;
  top: -2.8em;
  left: -0.3em;
  font-size: 10px;
  /* margin: 80px auto; */
  float: left;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.customChatBot1 .loader:before,
.customChatBot1 .loader:after {
  content: "";
  position: absolute;
  width: 20px;
  top: 0;
  top: 0;
}
.customChatBot1 .loader:before {
  left: 1.2em;
  -webkit-animation-delay: -0.32s;
  width: 6px;
  top: 0;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.customChatBot1 .loader:after {
  left: 2.3em;
  width: 6px;
  top: 0;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loaderMessageBox {
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .navigation-menu li {
    margin: 0 5px !important;
  }
  .MuiGrid-root.mainHeaderCol1 {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .MuiGrid-root.mainHeaderCol1 {
    margin-right: 0px;
  }
}
@media (max-width: 550px) {
  .customChatBot1 .messageTextBot {
    margin-right: 7px !important;
  }
  .customChatBot1 .messageTextUser {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .customChatBot1 .white-text {
    word-break: break-word;
  }
}

.customChatBot1 .canvas {
  display: block;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.customChatBot1 .ball {
  display: inline-block;
  width: 8px;
  height: 9px;
  border-radius: 11px;
  margin: 0 2px;
  -webkit-animation: 2s bounce ease infinite;
          animation: 2s bounce ease infinite;

  animation: 2s bounce ease infinite;
}
.customChatBot1 .blue {
  background-color: #0053df;
}
.customChatBot1 .red {
  background-color: #952b2b;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.customChatBot1 .yellow {
  background-color: #ff7510;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.customChatBot1 .green {
  background-color: #34a952;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
@-webkit-keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}
@keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}

.quickButtonDiv {
  display: inline-block;
  padding-left: 0px !important;
  padding-right: 20px !important;
  width: 40%;
}
.quickButton {
  padding: 5px 10px !important;
  margin: 2px;
  border-radius: 13px !important;
  background-color: #fd6a4b !important;
  color: #ffffff !important;
  border: none !important;
  width: 100%;
  /* background-image: linear-gradient(90deg,#1500ff,#7c00f9); */
}

.quickButtonDiv .btn-primary {
  border: 1px solid #fd6a4b !important;
}

.button_text {
  font-size: 14px;
  word-break: break-word;
}

@media only screen and (max-width: 550px) {
  .quickButtonDiv {
    width: 100%;
    padding-bottom: 10px !important;
    padding-right: 0 !important;
  }
}

.customChatBot1 .card_view_parent {
  padding-top: 4px;
}
.customChatBot1 .singleCardParent {
  margin-right: 2px;
  margin-left: 2px;
  border: 0px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  display: inline-block;
  border: solid 1px #e24c2d;
  cursor: pointer;
  /* height: 147px; */
  width: 128px;
  overflow: hidden;
}
.singleCardParentIn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customChatBot1 .card_main_body {
  overflow: auto;
  overflow-y: scroll;
  margin-left: 10px;
}
.allCardParent {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.customChatBot1 .card_image {
  width: 100%;
  height: 104px;
  object-fit: cover;
}
.customChatBot1 .card_content {
  /* padding-top: 1px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  background-color: #f1f0f0;
  height: 36px; */
  display: none;
}
.customChatBot1 .h5CardContent {
  margin-top: 5px;
  color: "#000000";
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 2px;
}
.customChatBot1 .card_content_button {
  padding-top: 1px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #fd6a4b;
}

.h5CardContent_button {
  margin-top: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 1px;
  color: #fff;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
}

.card_content_button {
  height: 40px;
  display: flex;
  align-items: center;
}

.card_content_button:hover {
  background-color: hsl(253, 100%, 78%);
  color: #000;
  cursor: pointer;
}
.h5CardContent_button:hover {
  color: #000;
}

.disableCardParent {
  opacity: 0.5;
}

.imagesStyling {
  /* width: 200px; */
  /* lsdfdsf */
  width: 120px;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border: 1px solid #fc644c;
  padding: 10px;
}
.imagesStyling img {
  width: 100%;
  border-radius: 7px;
}

.chatbot_body {
  background-color: #fff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  padding-top: 4px;
}
.icon_twoLine {
  /*font-size: 35px !important;*/
  text-align: center;
  margin: 0 auto;
}
.QuickReplyMainParent {
  padding-left: 0;
  padding-top: 5px;
  padding-right: 70px;
}
.surveyCompleted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.thankLine {
  padding-top: 35px;
  width: 100%;
  text-align: center;
}
.surveyLogo {
  width: 100%;
  margin-top: 30px;
}

#chatbot_body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
#chatbot_body::-webkit-scrollbar-track {
  background: transparent;
}

#chatbot_body::-webkit-scrollbar-thumb {
  background: #40547b;
  border-radius: 20px;
}

.quickReplyContainer {
  padding-left: 26px;
  padding-top: 10px;
  max-width: 95%;
}

.lineUp {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
}

.lineUp:before {
  content: "";
  background: #fd6a4b;
  width: 400px;
  height: 2px;
  position: absolute;
  left: 28%;
  top: -16px;
}

.createSurveyBtn {
  width: auto;
  padding: 6px 20px !important;
}

@media only screen and (max-width: 550px) {
  .lineUp:before {
    width: 100% !important;
    left: 0 !important;
  }
}

/**** LATEST CSS ****/

.footerInputContainer input {
  background: #fff !important;
  padding: 8px 10px;
  border-radius: 10px;
  padding-right: 100px;
}

.sendButtonContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
}
.send_icon {
  max-width: 12px;
  margin-left: 5px;
  margin-top: 1px;
}

.sendButtonContainer button {
  background: #40547b;
  color: #fff;
  width: auto;
  padding: 3px 20px;
  border-radius: 9px;
  border: 2px solid #40547b;
  height: 100%;
  display: flex;
  align-items: center;
}

.helpButtonContainer button {
  z-index: 20;
  background: #40547b;
  color: #fff;
  width: auto;
  padding: 3px 20px;
  border-radius: 9px;
  border: 2px solid #40547b;
}

.footerMessageContainer {
  position: relative;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.poweredByFooter {
  text-align: right;
  margin-top: 10px;
}

/***** OLD CSS  *****/
.customChatBot1 .footerTextInput {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  /* border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; */
  border-radius: 20px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 10px;
}
.customChatBot1 .row {
  margin: 10px auto !important;
  height: 40px;
}

.customChatBot1 .sendMessage {
  padding-right: 5px;
  padding-left: 5px;
}
.customChatBot1 .sendInoutBar {
  background: transparent;
  outline: none;
  border: 0px;
  width: 100%;
  padding-left: 10px;
}
.customChatBot1 .sendMessageButton {
  display: inline-block;
  padding: 0px !important;
  cursor: pointer;
  margin: 0px 0px 9px 15px;

  vertical-align: middle;
}
/* .customChatBot1 .sendMessageButton:hover{
  border: 1px solid #1c4fc3;
  border-radius: 100%;
  background-image: linear-gradient(to right, #8b00ff , #6000c1);
  color: #fff;
} */
.customChatBot1 .footerTextInput .col-md-2 {
  max-width: 6% !important;
}
.customChatBot1 .footerTextInput .col-md-2:hover {
  /* border: 1px solid #1c4fc3; */
  /* border-radius: 100%; */
  /* background-color: #1c4fc3; */
  color: #40547b;
}
.customChatBot1 .sendMessageButton .material-icons {
  margin-left: 0px;
  margin-top: 8px;
}
.customChatBot1 .poweredByFooter {
  font-size: 10px;
  text-align: right;
  color: #fff;
}
.customChatBot1 .poweredByFooter a {
  color: #fff !important;
}
.customChatBot1 .footerParent {
  padding: 15px 10px;
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 2px solid #e6e6e6;
  /* background-color: #fff; */
  margin-top: 1px;
}
.footerHelpButton {
  display: inline-block;
  color: "#1C4FC3";
  font-weight: 600;
  background: #40547b;
  /* background: #e9ecef; */
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
  color: #fff;
  padding-right: 20px;
  margin-bottom: 2px;
  margin-left: 10px;
  padding-left: 20px;
}

.footerHelpButton:hover {
  background: #e9ecef;
  color: #e24c2d;
  cursor: pointer;
  border: 1px solid #e24c2d;
}
.redmic {
  display: inline-block;
  /* margin-top: 5px; */
  cursor: pointer;
  vertical-align: middle;

  color: #ff0000;
}
.micButton {
  display: inline-block;
  margin-bottom: 5px;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 5px;
  padding: 0% !important;
}

.micButton:hover {
  border: 1px solid;
  border-radius: 100%;
  background-color: #1c4fc3;
  color: #fff;
}

.customChatBot1 .footerTextInput .col-md-3 {
  max-width: 9% !important;
  /* border: 1px solid ;
    border-radius: 100%; */
}

.customChatBot1 .redmic .canvas {
  display: block;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.customChatBot1 .redmic .ball {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 11px;
  margin: 0 2px;
  -webkit-animation: 2s bounce ease infinite;
          animation: 2s bounce ease infinite;
  animation: 2s bounce ease infinite;
}
.customChatBot1 .redmic .blue {
  background-color: #0053df;
}
.customChatBot1 .redmic .red {
  background-color: #952b2b;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.customChatBot1 .redmic .yellow {
  background-color: #ff7510;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.customChatBot1 .redmic .green {
  background-color: #34a952;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

@-webkit-keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}

@keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}

#container_mic {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

#container_mic .circle {
  border-radius: 50%;
  background-color: #1c4fc3;
  width: 22px;
  height: 22px;

  position: absolute;
  opacity: 0;
  -webkit-animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
          animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  top: 3px;
}

@-webkit-keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

.bottomFooterContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.foot_icon {
  max-width: 20px;
}
.footSurveyContainer h2 {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
.surveyFootInfoContainer p {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
}
.ipDiv {
  position: relative;
  flex: 0 0 82%;
}
.footerMessageContainer {
  display: flex;
  justify-content: space-around;
}
.flexFooter {
  display: flex;
  align-items: center;
  grid-column-gap: 1px;
  -webkit-column-gap: 1px;
          column-gap: 1px;
}

.surveyFootInfoContainer img {
  width: 100%;
  max-width: 115px;
}

.surveyFootInfoContainer {
  position: relative;
}

.surveyFootInfoContainer a {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 950px) {
  .footerMessageContainer {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .footerMessageContainer {
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: unset;
    -webkit-column-gap: unset;
            column-gap: unset;
  }

  .surveyFootInfoContainer {
    align-self: center;
  }
}
@media only screen and (max-width:400px){
  .ipDiv {
    position: relative;
    flex: 0 0 76%;
  }
  .sendButtonContainer button {
    padding: 2px 6px;
  }
}
.customChatBot1 .chat-window {
  width: 100%;
  max-width: 60%;
  height: 83vh;
  box-sizing: border-box;
  box-shadow: 2px 1px 10px 3px #9d888899;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 99;
  background: #ffffff;
  margin: auto;
  border-radius: 8px !important;
}
.chat-window-div {
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url(/static/media/surveyBg.db90e7f3.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.iframe-chat-window {
  width: 100%;
  height: auto !important;
  display: flex;
  background-image: none !important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.iframe-chat-window .chat-window {
  width: 100%;
}

/* .iframe-chat-window .chatbot_body {
  height: 300px;
} */

.surveyLogoContainer {
  margin: 20px 0px 0px;
  width: 100%;
  max-width: 60%;
  display: flex;
  justify-content: center;
}

.surveyLogoContainer img {
  width: 100%;
  max-width: 250px;
  height: auto;
}

.iframe-chat-window .chat-window {
  height: 500px;
}

@media only screen and (max-width: 950px) {
  .customChatBot1 .chat-window {
    width: 90%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    max-width: 90%;
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  .customChatBot1 .chat-window {
    width: 100%;
    height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px !important;
    /* border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
  }
}

.floatingLogo {
  position: absolute;
  bottom: 21px;
  right: 28px;
}

.floatingLogo p {
  margin-bottom: 1px;
}

.floatingLogo img {
  max-width: 200px;
}

.floatingLogo a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cardCon {
  display: inline-block;
}

.disabledSurveyContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.disabledSurveyContainer a {
  background: #40547b;
  color: #fff;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 18px;
  border: 2px solid #40547b;
  font-weight: 600;
}

.disabledSurveyContainer a:hover {
  background: #fff;
  color: #40547b;
  text-decoration: none;
}

.disabledSurveyContainer h3:nth-child(2) {
  position: relative;
}

.disabledSurveyContainer h3:nth-child(2):before {
  content: "";
  background: #67646447;
  width: 350px;
  height: 2px;
  position: absolute;
  bottom: -18px;
  left: 65px;
}

.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loaderContainer img {
  max-width: 30%;
}

.loaderContainer p {
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

@media only screen and (max-width: 550px) {
  .customChatBot1 .chat-window {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    flex: 1 0 auto;
  }
}

.mainHeaderContainer {
  width: 100%;
  padding: 10px 0px;
  max-width: 90%;
}

.mainHeaderCol1 img {
  width: 100%;
  max-width: 250px;
}

.mainHeaderCol2,
.mainHeaderCol3 {
  align-self: center;
}

.mainHeaderCol2 ul,
.mainHeaderCol3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
    /* justify-content: space-evenly; */
}

.mainHeaderCol3 ul {
  justify-content: flex-end;
  grid-column-gap: 2px;
  -webkit-column-gap: 2px;
          column-gap: 2px;
}

.mainHeaderCol2 ul {
  justify-content: center;
}
.mainHeaderCol2 ul li,
.mainHeaderCol3 ul li {
  font-weight: 700;
  position: relative;
  font-size: 16px;
}
.mainHeaderCol2 ul li:first-child{
  margin-left: 0;
}
.mainHeaderCol2 ul li a,
.mainHeaderCol3 ul li a {
  color: #000 !important;
  text-decoration: none;
}

.mainHeaderCol2 ul li:after,
.mainHeaderCol3 ul li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: -9px;
  right: 0;
  transform: scaleX(0);
  transition: all 0.5s ease-in-out;
}

.mainHeaderCol2 ul li:hover:after,
.mainHeaderCol3 ul li:hover:after {
  transform: scaleX(1);
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
  display: flex;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li.none {
  margin: 0px !important;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #40547b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.expanded {
  background-color: #ffffff;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  top: 100%;
  z-index: 99999;
}
.navigation-menu.expanded {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
  border-radius: 8px;
  padding-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .hamburger {
    display: block;
    position: absolute;
    right: 0;
  }
  .mainHeaderBlock {
    position: relative;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    margin: 0;
    width: 100%;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu .mainHeaderCol2 ul li a,
  .navigation-menu .mainHeaderCol3 ul li a {
    color: #ffffff !important;
  }
  .mainHeaderCol2 ul li:after,
  .mainHeaderCol3 ul li:after {
    bottom: 0px;
    background: #ffffff;
  }
  .none {
    display: none;
  }
  .navigation-menu li a {
    width: 100%;
    padding: 25px 0 10px 0;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
  .navigation-menu.expanded {
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .mainHeaderContainer {
    display: none;
  }
  .mainHeaderCol1 img {
    max-width: 200px !important;
  }
}

